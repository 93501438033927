import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Bauzinsen = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Bauzinsen – Das Wichtigste auf einen Blick" showCalc={false} />
            <Article>
                <p>
                    Bei der Planung einer Immobilienfinanzierung fühlt man sich manchmal, als würde man eine ganze
                    wirtschaftswissenschaftliche Grundausbildung durchlaufen – so viele neue Begriffe, Definitionen und
                    Regeln muss man sich dabei aneignen. Von Annuitätendarlehen bis{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    , von Laufzeit bis Zinssatz – am Ende hat man ein ganzes Begriffsregister gelernt. Genau dabei
                    möchten wir dir helfen. Heute geht es um das Thema Bauzinsen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was sind Bauzinsen?</h2>
                <p>
                    Der Begriff “Bauzinsen” ist lediglich eine weitere Bezeichnung für die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>
                    , die du bei einem Kredit für die{" "}
                    <Link to="/artikel/hausfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Haus-
                    </Link>{" "}
                    oder{" "}
                    <Link to="/artikel/wohnungsfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Wohnungsfinanzierung
                    </Link>{" "}
                    bezahlen musst. Damit ist jener Betrag gemeint, den du an das jeweilige Kreditinstitut überweist,
                    damit dir dieses Geld leiht. Unter Zinsen versteht man also ganz allgemein den Preis für das Risiko,
                    das eine Bank auf sich nimmt, wenn sie dir Kapital für den Kauf einer Immobilie zur Verfügung
                    stellt. Je höher die Bank die Wahrscheinlichkeit einschätzt, dieses wieder zu bekommen, umso
                    geringer fallen die Zinsen dabei aus.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Richtig vergleichen: Nominaler und effektiver Zinssatz</h2>
                <p>
                    Wenn es um Zinse geht, ist es essentiell, folgende zwei Begriffe auseinanderzuhalten: Den nominalen
                    und den effektiven Zinssatz. Unter dem nominalen Zinssatz versteht man schlicht das Entgelt, das du
                    für deinen Kredit an die Kreditgeber:in entrichten musst. Die fälligen Nebenkosten sind darin noch
                    nicht enthalten. Diese werden erst im effektiven Zinssatz berücksichtigt, weshalb nur er sich für
                    den Vergleich von Zinssätzen verschiedener Anbieter eignet.
                </p>
                <hr />

                <h2>Woraus ergibt sich die Höhe der Bauzinsen?</h2>
                <p>
                    Unabhängig vom Zinsmodell, für das du dich entscheidest, ist die konkrete Höhe der Zinsen vor allem
                    von deiner Bonität sowie dem Anteil an Eigenmitteln abhängig, den du für die Finanzierung aufwenden
                    kannst. Seit mit Juli 2020 neue Kreditregeln in Kraft getreten sind, musst du deine
                    Immobilienfinanzierung mit mindestens 20 % Eigenkapital bestreiten. Je mehr Eigenmittel du
                    einbringen kannst, umso geringer fallen jedoch auch deine Zinsen aus. Deine Bonität wird von der
                    Bank in einem Ratingprozess ermittelt, in dem folgende Parameter berücksichtigt werden:
                </p>
                <ul>
                    <li>Persönliche Angaben über deine Familiensituation</li>
                    <li>Deine finanzielle Lage</li>
                    <li>Verfügbare Vermögenswerte</li>
                    <li>Eventuelle finanzielle Verbindlichkeiten</li>
                </ul>
                <p>
                    Je höher deine Bonität eingestuft wird, umso wahrscheinlicher ist es nicht nur, dass du die
                    beantragte Kreditsumme erhältst. Auch die Zinsen, die du bei der Rückzahlung zu entrichten hast,
                    werden geringer ausfallen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"bauzinsen"}></BreadcrumbList>
            <ArticleStructuredData page={"bauzinsen"} heading={"Bauzinsen – Das Wichtigste auf einen Blick"} />
        </Layout>
    );
};

export default Bauzinsen;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.artikel.bauzinsen"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
